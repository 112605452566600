// vendors
import React from "react"
import { css } from "@emotion/core"
import { graphql } from "gatsby"

// components
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Accordion from "../components/Accordion/Accordion"

// utils
import wrapper from "../utils/wrapper"
import mediaQuery from "../utils/media-query"
import { fontSizes } from "../styles/variables"

const LinksSection = ({ data }) => {
  const linksSubSection = data.edges
    .reduce((acc, { node }) => {
      const isRelated = !!node.relatedRegion
      let group = undefined

      if (isRelated) {
        group = acc.find(({ name }) => name === node.relatedRegion.title)
      } else {
        group = acc.find(({ name }) => name === null)
      }

      if (group === undefined) {
        return [
          ...acc,
          {
            name: isRelated ? node.relatedRegion.title : null,
            regionNumber: isRelated ? node.relatedRegion.regionNumber : null,
            links: [{ name: node.name, url: node.url }],
          },
        ]
      }

      group.links.push({ name: node.name, url: node.url })

      return acc
    }, [])
    .sort((a, b) => {
      if (a.name === null) return -1
      if (b.name === null) return 1

      const nameA = a.name.toUpperCase() // ignore upper and lowercase
      const nameB = b.name.toUpperCase() // ignore upper and lowercase
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      // names must be equal
      return 0
    })

  return (
    <Accordion
      header={
        <h2
          css={css`
            margin: 0;
            font-size: ${fontSizes[1] / fontSizes[0]}em;
            font-weight: bold;

            ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
              font-size: ${fontSizes[3] / fontSizes[1]}em;
            }
          `}
        >
          {data.name}
        </h2>
      }
    >
      {linksSubSection.map((subSection) => (
        <div>
          {subSection.name && <h3>{subSection.name}</h3>}

          <ul>
            {subSection.links.map((link) => (
              <li>
                <a href={link.url}>{link.name}</a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Accordion>
  )
}

const LiensUtilsPage = ({ data }) => {
  const breakpoint = 1024

  return (
    <Layout>
      <Seo
        description="Étudiants en médecine, externes et résidents. Voici une liste de liens pouvant vous être utiles si vous désirez vivre une expérience en médecine en région québécoise."
        lang="fr"
        keywords={[]}
        title="Liens utiles pour planifier stage de médecine en région"
      />

      <article
        css={css`
          ${wrapper.bolt()}
          margin-bottom: ${fontSizes[9] / fontSizes[0]}em;
        `}
      >
        <header
          css={css`
            ${mediaQuery.greaterThen(breakpoint)} {
              ${wrapper.paddingLeft()}
            }
          `}
        >
          <h1>Liens utiles</h1>
        </header>

        <div
          css={css`
            display: grid;
            grid-row-gap: 30px;
            ${mediaQuery.lessThen(breakpoint)} {
              margin-left: calc(50% - 50vw);
              margin-right: calc(50% - 50vw);
            }

            ${mediaQuery.greaterThen(breakpoint)} {
              margin: auto;
              width: 100%;
              max-width: 990px;
            }
          `}
          className="styled-content"
        >
          {data.allSanityLink.sections.map((section) => (
            <LinksSection data={section} />
          ))}
        </div>
      </article>
    </Layout>
  )
}

export default LiensUtilsPage

export const query = graphql`
  query LiensUtilsPage {
    allSanityLink(sort: { fields: name, order: ASC }) {
      sections: group(field: section___name) {
        name: fieldValue
        edges {
          node {
            name
            relatedRegion {
              title
              regionNumber
            }
            url
          }
        }
      }
    }
  }
`
